<template>
  <div class="loginfo-container">
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-button size="small" type="success" v-on:click="handleAdd"
            >添加</el-button
          >
        </el-form-item>
        <el-form-item label="门店">
          <shopSelector v-model="filters.tenant"></shopSelector>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input
            size="small"
            v-model="filters.search"
            placeholder="关键字"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" v-on:click="queryPageList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      stripe
      border
      size="mini"
      :data="tableData"
      highlight-current-row
      expand-on-click-node="false"
      style="width: 100%"
    >
      <el-table-column
        prop="Name"
        label="名称"
        min-width="100"
        sortable
      ></el-table-column>
      <el-table-column
        prop="BeginDate"
        label="开始日期"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          {{ format.dateFormat(scope.row.BeginDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="EndDate" label="结束日期" min-width="100" sortable>
        <template slot-scope="scope">
          {{ format.dateFormat(scope.row.EndDate) }}
        </template>
      </el-table-column>
      <el-table-column prop="Times" label="可选时间段" min-width="120" sortable>
      </el-table-column>
      <el-table-column prop="Count" label="训练次数" min-width="120" sortable>
      </el-table-column>
      <el-table-column
        prop="MemberCount"
        label="已报名人数"
        width="120"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="Description"
        label="报名说明"
        min-width="120"
        sortable
      ></el-table-column>
      <el-table-column prop="AllowJoin" label="报名开关" width="100" sortable>
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.AllowJoin"
            @change="updateSave(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="InsertTime" label="创建时间" width="130" sortable>
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :width="100" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button type="text" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizesList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
    <campForm ref="campForm" @update="queryPageList" />
  </div>
</template>
<script>
import dateSelector from "@/components/dateSelector";
import { getPageList, save, del } from "@/api/camp/camp";
import campForm from "./components/campForm.vue";
import shopSelector from "@/components/shopSelector.vue";
export default {
  components: {
    dateSelector,
    campForm,
    shopSelector,
  },
  data() {
    return {
      filters: {
        search: "",
        tenant:"",
      },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      dialogStatus: "",
    };
  },
  methods: {
    queryPageList: function () {
      let data = {
        pageSize: this.pageSize,
        tenant:this.filters.tenant?this.filters.tenant.Key:"",
        pageIndex: this.pageIndex,
        search: this.filters.search,
      };
      getPageList(data).then((response) => {
        this.listLoading = false;
        if (response) {
          this.tableData = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.queryPageList());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.queryPageList();
    },
    handleAdd() {
      this.$refs.campForm.handleCreate();
    },
    handleUpdate(row) {
      this.$refs.campForm.handleUpdate(row);
    },
    handleDelete(row) {
      this.$confirm("确认删除该训练计划吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let parma = { key: row.Key };
        del(parma).then((res) => {
          if (res) {
            this.queryPageList();
          }
        });
      });
    },
    updateSave(row) {
      save(row).then((response) => {
        if (response) {
          this.getPageList();
        }
      });
    },
  },
  mounted() {
    this.queryPageList();
  },
};
</script>
  
<style lang='scss' scoped>
.loginfo-container {
  padding: 10px;
}

/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
  